<template>
  <div>
    <p class="text-h5">Diese Seite existiert nicht.</p>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>